import * as React from 'react';
import {GatsbyImage, IGatsbyImageData, getImage} from 'gatsby-plugin-image';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface ImageProps {
    imgObject: IGatsbyImageData,
    alt?: string
    className?: string
}

const Image: React.FC<ImageProps> = (props) => {
    const {alt, imgObject, className} = props;
    const image = getImage(imgObject);

    if (!image) throw Error(`Image not valid ${props}`);

    return <div className={className}><GatsbyImage image={image} alt={alt || ''}/></div>;
}
export default Image;
